import React from 'react';

interface Props {
  title: string;
  to: string;
  className?: string;
}

export const Button: React.FC<Props> = (props) => {
  const { className = '', to, title } = props;

  return (
    <a
      className={`flex items-center button whitespace-nowrap bg-white dark:bg-dark mt-8 hover:bg-opacity-100 hover:bg-white ${className}`}
      href={to}
    >
      <span className="hidden xs:inline">{title}</span>
      <span className="xs:hidden">{title}</span>
    </a>
  );
};
