import React from 'react';
import { Meta } from 'components/Meta';
import { RouteEnum } from 'model/route.enum';
import Layout from 'components/Layout';
import { graphql, PageProps } from 'gatsby';
import { AudioCards } from 'components/AudioCards';
import { GatsbyImage } from 'gatsby-plugin-image';
import Deutschlandfunk from 'resources/svg/deutschlandfunk.svg';
import Wdr from 'resources/svg/wdr.svg';
import DeutschlandfunkKultur from 'resources/svg/deutschlandfunk-kultur.svg';
import ZdtInfo from 'resources/svg/zdf-info.svg';
import Quarks from 'resources/svg/quarks.svg';
import StadtKoeln from 'resources/svg/stadt-koeln.svg';
import StaatlicheSchloesser from 'resources/svg/staatliche-schloesser.svg';
import 'resources/styles/pages/default.scss';
import { Button } from 'components/Button';

export const query = graphql`
  query {
    underline1: file(relativePath: { eq: "underline-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    underline2: file(relativePath: { eq: "underline-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    underline3: file(relativePath: { eq: "underline-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    underline4: file(relativePath: { eq: "underline-4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    allProductions: file(relativePath: { eq: "all-productions.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    profile: file(relativePath: { eq: "hanna.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    audioCards: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "markdown/audio/.*.md$/" }
        frontmatter: { is_displayed: { eq: true } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        id
        frontmatter {
          audio_file {
            publicURL
          }
          cover {
            copyright
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            subtitle
          }
          slug
          tags {
            text
          }
          title
        }
      }
    }
  }
`;

type DataProps = {
  allProductions: GatsbyTypes.File;
  audioCards: GatsbyTypes.MarkdownRemarkConnection;
  profile: GatsbyTypes.File;
  underline1: GatsbyTypes.File;
  underline2: GatsbyTypes.File;
  underline3: GatsbyTypes.File;
  underline4: GatsbyTypes.File;
};

const Page: React.FC<PageProps<DataProps>> = (props) => {
  const { data } = props;
  const length = data.audioCards.nodes.length;

  return (
    <Layout>
      <main className="default">
        <section className="my-0">
          <div className="py-[20%] md:flex md:flex-col md:justify-center md:py-16 md:min-h-[28rem] md:h-[66vh] md:max-h-[48rem]">
            <h1 className="mt-0 max-w-[15ch]">
              Audio, so groß wie{' '}
              <span className="relative">
                die Story
                <GatsbyImage
                  className="absolute left-0 w-full top-1/3 -z-10 dark:opacity-40"
                  image={data.underline1.childImageSharp!.gatsbyImageData}
                  alt="Unterstrichen"
                />
              </span>{' '}
              dahinter.
            </h1>
            <p>
              <strong>stegermeister®</strong> entwickelt Audioproduktionen mit
              Geschichte – Feature, Hörspiele und Podcasts. Wir konzipieren,
              tüfteln, produzieren: Von der Idee bis ins Ohr.
            </p>
          </div>
        </section>

        <section className="flex flex-col my-12">
          <AudioCards
            items={data.audioCards.nodes
              .slice(0, length > 5 ? 5 : length)
              .concat([
                {
                  id: '0',
                  frontmatter: {
                    title: 'Alle Produktionen',
                    slug: '',
                    cover: { image: data.allProductions },
                  },
                } as GatsbyTypes.MarkdownRemark,
              ])}
          />
          <Button
            className="-mx-3 lg:-mx-4 xl:-mx-6 self-end"
            to={RouteEnum.ALL_PRODUCTIONS}
            title="Alle Produktionen"
          />
        </section>

        <section>
          <div className="flex flex-col justify-between lg:flex-row lg:px-8 lg:items-center">
            <div className="my-4 lg:my-0 w-41 sm:w-60.75">
              <Deutschlandfunk />
            </div>
            <div className="my-4 lg:my-0 w-27.5 sm:w-27.5">
              <Wdr />
            </div>
            <div className="my-4 lg:my-0 w-27.5 sm:w-41">
              <ZdtInfo />
            </div>
            <div className="my-4 lg:my-0 w-60.75 sm:w-79.5">
              <DeutschlandfunkKultur />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:flex-row lg:px-8 lg:items-center lg:mt-16">
            <div className="sm:py-4 my-1 w-[30%] sm:w-1/3 flex lg:justify-center">
              <Quarks />
            </div>
            <div className="my-2 lg:my-0 w-[55%] sm:w-1/3 flex lg:justify-center">
              <StadtKoeln />
            </div>
            <div className="my-1 sm:my-4 lg:my-0 w-[25%] sm:w-1/3 flex lg:justify-center">
              <StaatlicheSchloesser />
            </div>
          </div>
        </section>

        <section>
          <h1>
            <span className="relative">
              All the way:
              <GatsbyImage
                className="absolute w-42/40 -left-1 md:-left-2 top-1/3 -z-10 dark:opacity-40"
                image={data.underline2.childImageSharp!.gatsbyImageData}
                alt="Unterstrichen"
              />
            </span>
            <br />
            Von der Idee bis ins Ohr.
          </h1>
          <p>
            Bei <strong>stegermeister®</strong> sind erfahrene
            Medienmacher*innen zu Hause. Mit reichlich Erfahrung in dem, was wir
            tun, sorgen wir für Audioproduktionen, die man einfach nicht skippen
            kann. Wir entwickeln Ideen, beraten, kommunizieren mit Sendern und
            Plattformen, setzen fertige Skripte um oder produzieren von Anfang
            bis Ende – whatever works.
          </p>
          <p className="mt-12">
            Büro und Schnitt liegen im Herzen von Köln – aufnahmetechnisch sind
            wir deutschlandweit im Einsatz. Mit erstklassigen Journalist*innen
            und Autor*innen, Redakteur*innen und Regisseur*innen,
            Toningenieur*innen und Schneidekünstler*innen liefert unser Netzwerk
            alles, was eine mitreißende Audioproduktion braucht.
          </p>
        </section>

        <section className="grid grid-cols-12 gap-4 lg:gap-8">
          <div className="relative col-span-12 -mx-3 xs:col-span-7 sm:col-span-8 xs:mx-0">
            <GatsbyImage
              imgClassName="rounded-2xl md:rounded-3xl"
              className="w-full overflow-hidden rounded-2xl shadow-profile xs:left-[-50%] xs:w-[150%] md:rounded-3xl"
              image={data.profile.childImageSharp!.gatsbyImageData}
              alt="Hanna Steger"
            />
          </div>
          <div className="relative col-span-12 mt-8 text-sm max-w-prose xs:mt-0 xs:max-w-none xs:text-xs xs:col-span-5 sm:text-sm sm:col-span-4 xs:self-center lg:text-base">
            <div className="mb-2 text-2xl md:text-3xl md:mb-4">
              <strong className="relative">
                Hanna Steger
                <GatsbyImage
                  className="absolute rounded-r-sm w-24/20 -left-2 top-1/2 -z-10 rounded-x-sm dark:opacity-40"
                  image={data.underline4.childImageSharp!.gatsbyImageData}
                  alt="Unterstrichen"
                />
              </strong>
            </div>
            <div className="flex flex-col">
              Namensgeberin und kreativer Kopf. Urheberin des einzigen
              Unter-Wasser-Hilfe-Schreis im WDR Soundarchiv.
              <Button
                to={RouteEnum.PROFILE}
                title="Über mich"
                className="self-start"
              />
            </div>
          </div>
        </section>

        <section id="idea">
          <h1>
            Du hast eine Idee?
            <br />
            <span className="relative">
              Klingt
              <GatsbyImage
                className="absolute w-42/40 -left-1 md:-left-2 top-1/2 -z-10 dark:opacity-40"
                image={data.underline3.childImageSharp!.gatsbyImageData}
                alt="Unterstrichen"
              />
            </span>{' '}
            großartig.
          </h1>
          <p>
            Jede gute Idee verdient es, gehört zu werden. Sprechen wir drüber.
          </p>
        </section>

        <section id="info">
          <a
            className="flex justify-start hover:text-dark hover:text-opacity-90 dark:hover:text-yellow"
            href={RouteEnum.START_PROJECT}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="relative h1 mb-36">
              info@stegermeister.de{' '}
              <GatsbyImage
                className="absolute w-42/40 -left-1 md:-left-2 top-1/3 -z-10 dark:opacity-40"
                image={data.underline4.childImageSharp!.gatsbyImageData}
                alt="Unterstrichen"
              />
            </span>
          </a>
          <address>
            <strong>stegermeister®</strong>
            <br />
            Merlostraße 2<br />
            50668 Köln
            <br />
            <a href={RouteEnum.PHONE} rel="noopener noreferrer" target="_blank">
              +49 221 2984 6666
            </a>
          </address>
        </section>
      </main>
    </Layout>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Page;
